// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/images/logo-add-hovered.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/images/logo-add.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card-wrapper[data-v-502441b6]{background:linear-gradient(158.84deg,hsla(0,0%,100%,.24) -52.29%,hsla(0,0%,100%,0) 103.57%);filter:drop-shadow(-5.88385px 6.53761px 3.92256px rgba(0,0,0,.08));border-radius:8.49889px;display:flex;flex-direction:column;overflow:hidden;position:relative;padding-bottom:8px}.card-wrapper[data-v-502441b6]:hover{outline:2px solid #00d7ff}.card-content__wrapper[data-v-502441b6]{flex:1;align-self:stretch;display:flex;align-items:center;justify-content:center;text-decoration:none;flex-direction:column;cursor:pointer}body[data-v-502441b6]:after{display:none;content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.card-icon[data-v-502441b6]{display:inline-block;width:66px;height:66px}.card-content__wrapper:hover .card-icon[data-v-502441b6],.card-icon[data-v-502441b6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%}.card-content__wrapper--multi .card-icon[data-v-502441b6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;width:35px;height:35px;background-size:35px,35px!important}.card-title[data-v-502441b6]{font:normal 12px Poppins;text-align:center;color:#fff;margin-top:10px}.card-content__wrapper:hover .card-title[data-v-502441b6]{color:#00d7ff}.button-separator[data-v-502441b6]{border:1px solid #000}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
