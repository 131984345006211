<script setup>
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { ref, onMounted, computed } from 'vue'
import { LoadingIndicator, SelectStorageItemDialog, PopupMenu } from '@/components/creator'
import ItemsCards from '@/components/ItemsCards.vue'
import { encodeUriFrom, addSearchParamToUrl, getLoadingState, apiCallWithState, getPreviewUrlByContentItem } from '@/utils/common'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { processError } from '@/helpers/errors'

const props = defineProps({
  spaceRef: {
    type: Object,
    required: false,
  },
  showLoadingIndicator: {
    type: Boolean,
    default: false,
  },
  platformAvatars: {
    type: Boolean,
    default: false,
  },
  returnTab: {
    type: String,
    default: '',
  },
  showDefaultSet: {
    type: Boolean,
    default: false,
  },
})

const state = ref(getLoadingState())
const menu = ref()

const returnUrl = computed(() => {
  if (props.returnTab) {
    return encodeUriFrom(addSearchParamToUrl(document.location.href, 'tab', props.returnTab))
  } else {
    return encodeUriFrom(document.location.href)
  }
})

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiSpaceAvatarsList,
  apiSpaceAvatarsCreate,
  apiSpaceAvatarsDelete,
  apiPlatformAvatarsList,
  apiPlatformAvatarsCreate,
  apiPlatformAvatarsDelete,
  apiContentItemsListByIds,
} = useActions([
  'apiSpaceAvatarsList',
  'apiSpaceAvatarsCreate',
  'apiSpaceAvatarsDelete',
  'apiPlatformAvatarsList',
  'apiPlatformAvatarsCreate',
  'apiPlatformAvatarsDelete',
  'apiContentItemsListByIds',
])

const cardsCreateButtons = computed(() => {
  let query
  if (props.platformAvatars) {
    query = { typeInit: 'avatar', typeDisabled: 1, platform: 1, from: returnUrl.value }
  } else {
    query = { typeInit: 'avatar', typeDisabled: 1, space: props.spaceRef.id, from: returnUrl.value }
  }

  const buttons = [
    {
      title: 'Add from storage',
      click: addAvatarFromStorage,
    },
    {
      title: 'Add custom avatar',
      name: 'StorageNew',
      query,
    },
  ]

  return buttons
})

const selectAvatarDialog = ref()
const detachAvatarDialog = ref(null)

const avatars = ref([])

const cardItems = computed(() => {
  const _items = []
  for (const item of avatars.value) {
    const previewSrc = item.glbmodel
      ? `${process.env.VUE_APP_CONTENT_BASE_URL}/${item.glbmodel}`
      : ''
    const previewPoster = getPreviewUrlByContentItem(item.contentItem)

    _items.push({
      id: item.id,
      title: item.name,
      routePath: 'StorageItem',
      routeParams: { id: item.id },
      routeQuery: { from: returnUrl.value },
      link: undefined,
      previewPoster,
      previewSrc,
      label: undefined,
      sourceObject: item,
      menu: menu.value,
    })
  }

  if (props.showDefaultSet) {
    const defaultAvatarsSetPoster = require('@/images/default-avatars-set.png')

    _items.push({
      id: 'default',
      title: 'Default avatars set',
      link: undefined,
      previewPoster: defaultAvatarsSetPoster,
      previewSrc: undefined,
      label: undefined,
      sourceObject: undefined,
    })
  }

  return _items
})

const cardItemIds = computed(() => {
  return cardItems.value.map((item) => item.id)
})

async function loadAvatars () {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    let rawData
    if (props.platformAvatars) {
      rawData = (await apiPlatformAvatarsList()).content
    } else {
      rawData = (await apiSpaceAvatarsList({ spaceId: props.spaceRef.id })).content
    }

    avatars.value = []
    if (!rawData.length) return

    const avatarIds = rawData.map(a => a.content_item.id)

    const contentItems = (await apiContentItemsListByIds({ ids: avatarIds })).content

    for (const key in rawData) {
      const contentItem = contentItems.filter(
        (item) => item.id === rawData[key].content_item.id
      )[0]

      if (!contentItem) continue

      avatars.value.push({
        ...contentItem,
        avatarContentItem: rawData[key],
      })
    }
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
  }
}

async function addAvatarFromStorage (buttonData) {
  const selectedAvatars = await selectAvatarDialog.value.show()

  for (const avatar of selectedAvatars) {
    if (props.platformAvatars) {
      await apiCallWithState(apiPlatformAvatarsCreate, { contentItemRef: avatar }, state)
    } else {
      await apiCallWithState(apiSpaceAvatarsCreate, { spaceRef: props.spaceRef, contentItemRef: avatar }, state)
    }
  }

  loadAvatars()
}

async function detachClicked (item, params) {
  const result = await detachAvatarDialog.value.show()
  if (result === 'delete') {
    if (props.platformAvatars) {
      await apiCallWithState(apiPlatformAvatarsDelete, { platformAvatarId: params.sourceObject.avatarContentItem.id }, state)
    } else {
      await apiCallWithState(apiSpaceAvatarsDelete, { spaceAvatarId: params.sourceObject.avatarContentItem.id }, state)
    }

    loadAvatars()
  }
}

function itemClicked (item) {
  if (item.sourceObject === undefined) {
    // clicked default avatars set

    emit('defaultAvatarsSetClicked')
  }
}

onMounted(async () => {
  loadAvatars()
})

defineExpose({ state, avatars })
const emit = defineEmits(['defaultAvatarsSetClicked'])
</script>

<template>
  <div class="avatars-cards__wrapper">
    <LoadingIndicator :state="state" all v-if="props.showLoadingIndicator"/>

    <ItemsCards
      :items="cardItems"
      cardWidth="200px"
      cardHeight="254px"
      :createButton="cardsCreateButtons"
      @click="itemClicked"
    />

    <SelectStorageItemDialog
      title="Select avatar"
      ref="selectAvatarDialog"
      :itemType="creatorItemTypes.avatar"
      :exclude="cardItemIds"
    />

    <PopupMenu
      ref="menu"
      :items="[{ title: 'Detach', click: detachClicked }]"
      position="right"
      width="112px"
    />

    <Dialog
      title="Detach Avatar?"
      ref="detachAvatarDialog"
      :buttons="[DialogButtons.Cancel, { ...DialogButtons.DeletePrimary, title: 'Detach' } ]"
    >
      <div class="g-text-center">
        Avatar will be detached from this world, but<br>
        still available in your storage.
      </div>
    </Dialog>
  </div>
</template>

<style scoped lang="scss">

.avatars-cards__wrapper {
  overflow: auto;
}
</style>
